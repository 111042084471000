import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export default function Accordion2() {
  return (
    <Accordion>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>Playbill Sizes</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <img
            src="/adSpecs/Playbill-Dimensions.png"
            alt="playbill size ad dimensions"
          />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>Magazine Sizes</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <img
            src="/adSpecs/Magazine-Dimensions.png"
            alt="Magazine size ad dimensions"
          />
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>Outdoor Sports Guide Sizes</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <img
            src="/adSpecs/Sports-Guide-Dimensions.png"
            alt="Outdoor Sports Guide size ad dimensions"
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}
