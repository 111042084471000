import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Accordion2 from '../components/accordion';

const Generic = (props) => (
  <Layout>
    <Helmet>
      <title>Advertising Specifications - Mills Publishing</title>
      <meta
        name="description"
        content="Advertising specifications for Mills Publishing’s publications."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Advertising Specifications</h1>
          </header>
          <Accordion2 />

          <div className="grid-wrapper" style={{ fontSize: 0.8 + 'em' }}>
            <div className="box col-6">
              <p>
                Please forward the rate card specs and requirements to your
                Designer, or Advertising Agency.
              </p>
              <p>
                Advertisers take full responsibility for proofing the ad before
                submission. Submitted ads are considered proofed and approved by
                the clients.
              </p>
              <strong>Format Requirements</strong>
              <p>
                Preferred copy — Electronic files on e-mail, Flash Drive, TIFF,
                EPS, or high-resolution PDF files.
              </p>
              <p>
                All files with transparencies should be flattened (e.g.,
                Photoshop, Illustrator, InDesign).
              </p>
              <p>
                Acceptable formats include: Adobe Illustrator CC, or earlier,
                Photoshop CC, or earlier, Acrobat PDFs, and InDesign CC, or
                earlier.
              </p>
              <p>InDesign files must include images and fonts.</p>
              <p>Photoshop files need to be 300dpi or higher.</p>
              <p>
                If you are using CorelDraw please export your files as
                Illustrator files with fonts outlined.
              </p>
              <p>All PDF files must be prepared for printing.</p>
              <p>
                Please make sure there are no OPI images and that all images are
                CMYK, with all fonts embedded. DO NOT SUBMIT ANY ARTWORK FROM
                THE WEB.
              </p>
              <p>
                We do not accept ads created in the following programs:
                Microsoft Word, Publisher, or PowerPoint. If you are creating
                ads in these programs, please include images, we will recreate
                them and submit a proof.
              </p>
              <p>
                Mills Publishing accepts no responsibility for image setting
                errors, corrupt files; nor for providing proofs after file ready
                deadline. For more information, please contact Mills Art
                Department.
              </p>
            </div>
            <div className="box col-6">
              <strong>General Information</strong>
              <p>
                Graphic Arts Services: Advertisers who do not have an
                advertising agency and who request layouts and copy may obtain
                such services under a separate contract.
              </p>
              <p>
                Preferred Positions: Guaranteed at 10% additional to space rate;
                must have a signature of the publisher. The positioning of
                advertisements is at the discretion of the art director, except
                where specific preferred positions are covered by the written
                commitment of the publisher.
              </p>
              <p>
                Inserts and Gatefolds: Rates and specifications supplied upon
                request.
              </p>
              <ul>
                <li>
                  Closing date is the space reservation deadline. All contracts
                  and position requirements should be received by our office on,
                  or before this date to insure insertion.
                </li>

                <li>
                  All advertisements that are not file-ready and require
                  production work of any kind should be turned in by the
                  non-file-ready date to facilitate completion of the ad and any
                  proofreading by the advertiser.
                </li>

                <li>
                  Artwork that is to be furnished to us file-ready must be
                  turned in by the file-ready deadline. Additional late charges
                  will be assessed to any advertisement requiring production
                  work at this time.
                </li>

                <li>
                  15% off to recognized agencies for file-ready materials only.
                  2% off net space for payments received before publication. It
                  is understood that all orders for space are accepted subject
                  to our credit requirements.
                </li>
              </ul>
              <p>
                Delinquent accounts (received past due date) will incur service
                charges.
              </p>
              <p>
                See the contract for more complete details regarding credits,
                commission, discounts, and so on.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Generic;
